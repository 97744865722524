<template>
  <AppWrapper
    data-vue-component-name="Webinars"
    bgImage="backgrounds/webinars.jpg"
  >
    <ButtonBack @click="onBack" />

    <transition name="fade" mode="out-in" appear>
      <h1 :key="activeWebinar">
        {{ activeWebinar?.name || 'Webinars' }}
      </h1>
    </transition>

    <transition name="fade" mode="out-in" appear>
      <WebinarsList
        v-if="isNull(activeWebinar)"
        :items="webinarsList"
        @on-click="toggleActiveWebinar"
      />

      <WebinarsSingleView
        v-else
        :item="activeWebinar"
      />
    </transition>
  </AppWrapper>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { AppWrapper, ButtonBack } from '@xsys2/components';
import { isNull } from '@xsys2/functions';
import { metaController } from '@/modules';

metaController({
  title: 'Webinars Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/webinars.jpg',
      as: 'image',
    },
  ],
});

const store = useStore();
const router = useRouter();

store.dispatch('webinars/getData');

const webinarsList = computed(() => store.state.webinars.webinarsList);
const activeWebinar = ref(null);
const toggleActiveWebinar = webinar => (activeWebinar.value = webinar);

const onBack = () => {
  if (!isNull(activeWebinar.value)) {
    toggleActiveWebinar(null);
  } else {
    router.go(-1);
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="Webinars"] {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 32px;

  [data-vue-component-name="ButtonBack"] {
    margin-bottom: 32px;
  }

  h1 {
    font-size: 32px;
    color: #fff;
    font-weight: 800;
    margin-bottom: 24px;
  }

  [data-vue-component-name="WebinarsList"] {
    overflow-y: auto;
  }
}
</style>
